<template>
  <loading v-if="showLoading"></loading>
</template>

<script>
// import axios from "axios";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      showLoading: true,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      getMessages: "liffChatroom/getMessages",
      sendMessage: "liffChatroom/sendMessage",
      goToChatStaff: "liffChatroom/goToChatStaff",
    }),
    async init () {
      let sourceType = this.$route.query.source_type;
      let sourceId = this.$route.query.source_id;

      if (!sourceType || !sourceId) {
        this.showLoading = false;
        this.$swal.fire({
          type: "error",
          text: "網址錯誤，請使用正確網址進入"
        });
        return;
      }

      try {
        let data = await this.goToChatStaff({sourceType, sourceId});
        if (data.id) {
          this.$router.push({ name: "LiffChatroomShow", params: {id: data.id}, query: {as_staff: true} });
        } else {
          throw false;
        }
      } catch (error) {
        this.showLoading = false;

        let message = "系統錯誤";

        if (error.status === 404) {
          message = "找不到聊天室";
        }
        if (error.response.status === 'failed') {
          message = error.response.message;
        }

        this.$swal.fire({
          type: "error",
          text: message,
        });
      }
    }
  }
}
</script>
